<template>
  <div class="page">
    <div class="box1">
      <div class="main_box">
        <div class="ttl">项目总指挥中心<br />解决方案</div>
        <div class="sub_ttl">
          连通各分包单位进行协同管理，实时跟进项目进度，监控项目现场
        </div>
        <a
          class="btn"
          href="http://p.qiao.baidu.com/cps/chat?siteId=13160731&userId=27403999&siteToken=839057cc7afae96bcdd508339dc7a783"
          target="_blank"
        >
          咨询服务
        </a>
      </div>
    </div>
    <div class="box4">
      <div class="title">个性化定制功能</div>
      <div class="main_box" flex="main:justify">
        <div class="col" flex-box="0">
          <img
            class="icon"
            src="@/assets/img/ic_200_platform.png"
            alt="团队管理"
          />
          <div class="ttl">团队管理</div>
          <div class="desc">
            链接上下游大数据，结合公安系统认证接口，轻松建立班组、库工人库信息系统
          </div>
        </div>
        <div class="col" flex-box="0">
          <img
            class="icon"
            src="@/assets/img/ic_200_attendance.png"
            alt="考勤管理"
          />
          <div class="ttl">考勤管理</div>
          <div class="desc">
            提供移动场景和封闭场景考勤解决方案，个性化考勤规则，自动核算工资
          </div>
        </div>
        <div class="col" flex-box="0">
          <img
            class="icon"
            src="@/assets/img/ic_200_contract.png"
            alt="电子合同"
          />
          <div class="ttl">电子合同</div>
          <div class="desc">
            劳务合作在线化，涵盖合同审批、签署、归档、存证及风险管控全周期管理
          </div>
        </div>
        <div class="col" flex-box="0">
          <img
            class="icon"
            src="@/assets/img/ic_200_custom.png"
            alt="质量、安全管理"
          />
          <div class="ttl">质量、安全管理</div>
          <div class="desc">
            AI人脸识别违章人员，一键发布隐患单，高效指派整改人，违规记录入档
          </div>
        </div>
      </div>
      <div class="main_box" flex="main:justify">
        <div class="col" flex-box="0">
          <img
            class="icon"
            src="@/assets/img/ic_200_responsibility.png"
            alt="风险预警"
          />
          <div class="ttl">风险预警</div>
          <div class="desc">
            AI人工技能技术，自动为您的项目识别风险信息，提前预判减少施工风险
          </div>
        </div>
        <div class="col" flex-box="0">
          <img
            class="icon"
            src="@/assets/img/ic_200_release.png"
            alt="施工日志"
          />
          <div class="ttl">施工日志</div>
          <div class="desc">
            突破传统打造极简施工日志，10秒快速发布，可追溯历史日志记录，重现施工现场
          </div>
        </div>
        <div class="col" flex-box="0">
          <img
            class="icon"
            src="@/assets/img/ic_200_learning.png"
            alt="工人教育"
          />
          <div class="ttl">工人教育</div>
          <div class="desc">
            有趣的短视频教育形式，简短有趣更容易记忆吸收，还可以设置奖学金鼓励学习
          </div>
        </div>
        <div class="col" flex-box="0">
          <img
            class="icon"
            src="@/assets/img/ic_200_application.png"
            alt="更多功能"
          />
          <div class="ttl">更多功能</div>
          <div class="desc">
            进度管理、材料管理、劳务费用管理等其他功能请咨询客服定制
          </div>
        </div>
      </div>
    </div>
    <div class="box2">
      <div class="main_box">
        <div class="ttl">还原施工“现场”</div>
        <div class="sub_ttl">
          墨器星盘通过智能AI技术，结合智能硬件设备：考勤机、监控录像、环境监测、智能塔吊等设备，实时监控施工进度。
        </div>
      </div>
    </div>
    <div class="box3">
      <div class="title">方案应用场景</div>
      <div class="tabs">
        <div
          :class="['item', box3.crtIndex === i ? 'z-crt' : '']"
          v-for="(group, i) in box3.devices"
          :key="group.ttl"
          @click="box3.swiper.slideTo(i)"
        >
          {{ group.ttl }}
        </div>
      </div>
      <div class="main_box">
        <div class="swp_wrap">
          <swiper
            @ready="(sw) => (box3.swiper = sw)"
            @slideChange="() => (box3.crtIndex = box3.swiper.realIndex)"
            class="swp swiper-no-swiping"
          >
            <swiper-slide
              class="sld"
              v-for="(s, idx) in box3.devices"
              :key="idx"
            >
              <div class="group">
                <div
                  class="case_item"
                  @click.stop="showDeviceDetail(c.id)"
                  v-for="c in s.list"
                  :key="c.id"
                >
                  <div
                    class="cover"
                    :style="{
                      backgroundImage: `url(${c.cover})`,
                    }"
                  ></div>
                  <div class="info">
                    <div class="ttl">{{ c.name }}</div>
                    <div class="desc">{{ c.desc }}</div>
                    <div class="more" flex="cross:center">
                      了解详情<img
                        class="i"
                        src="@/assets/img/ic_32_箭头@3x.png"
                        alt=">"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </div>
    <div class="box5">
      <div class="title">方案应用场景</div>
      <div class="main_box">
        <div class="row" flex="main:justify">
          <div class="col" flex-box="0">
            <img
              class="pic"
              src="@/assets/img/项目场景_项目会议.jpg"
              alt="项目会议"
            />
            <div class="ttl">项目会议</div>
          </div>
          <div class="col" flex-box="0">
            <img
              class="pic"
              src="@/assets/img/项目场景_智慧工地观摩.jpg"
              alt="智慧工地观摩"
            />
            <div class="ttl">智慧工地观摩</div>
          </div>
          <div class="col" flex-box="0">
            <img
              class="pic"
              src="@/assets/img/项目场景_政府考察.jpg"
              alt="政府考察"
            />
            <div class="ttl">政府考察</div>
          </div>
        </div>
        <div class="row" flex="main:justify">
          <div class="col" flex-box="0">
            <img
              class="pic"
              src="@/assets/img/项目场景_客户考察.jpg"
              alt="客户考察"
            />
            <div class="ttl">客户考察</div>
          </div>
          <div class="col" flex-box="0">
            <img
              class="pic"
              src="@/assets/img/项目场景_班组培训.jpg"
              alt="班组培训"
            />
            <div class="ttl">班组培训</div>
          </div>
          <div class="col" flex-box="0">
            <img
              class="pic"
              src="@/assets/img/项目场景_日常管理.jpg"
              alt="日常管理"
            />
            <div class="ttl">日常管理</div>
          </div>
        </div>
      </div>
    </div>
    <div class="trial_bar">
      <div class="main_box" flex="main:justify cross:center">
        <div class="ttl">为您打造一套数智工程管理解决方案</div>
        <div class="btn" @click="showTrialModal">申请体验</div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Footer from "@/components/Footer.vue";
import devices from "@/data/devices";

const box3 = {
  swiper: null,
  crtIndex: 0,
  devices: [
    {
      ttl: "智能考勤设备",
      list: [2, 1, 12, 0].map((n) => devices[n]),
    },
    {
      ttl: "施工设备",
      list: [6, 7, 9, 8].map((n) => devices[n]),
    },
    {
      ttl: "安全检测设备",
      list: [5, 10, 3, 4].map((n) => devices[n]),
    },
    {
      ttl: "其他硬件设备",
      list: [11, 13].map((n) => devices[n]),
    },
  ],
};

export default {
  components: {
    Footer,
  },
  data() {
    return {
      box3,
    };
  },
  methods: {
    ...mapActions(["showTrialModal", "showDeviceDetail"]),
  },
};
</script>

<style lang="scss" scoped>
@import "@/theme.scss";

.box1 {
  background: url("~@/assets/bg/banner.jpg") no-repeat center center;
  background-size: cover;
  height: 32rem;
  overflow: hidden;

  .ttl {
    margin-top: 13.8rem;
    font-size: 2rem;
    font-weight: bold;
    color: #fff;
    line-height: 2.8rem;
    letter-spacing: 0.2rem;
  }

  .sub_ttl {
    margin-top: 1.1rem;
    font-size: 1rem;
    color: #fff;
    line-height: 1.5rem;
  }

  .btn {
    margin-top: 3rem;
    display: block;
    width: 6.6rem;
    height: 2rem;
    background-color: #2574ff;
    border-radius: 0.4rem;
    text-decoration: none;
    font-size: 0.9rem;
    font-weight: bold;
    text-align: center;
    color: #fff;
    line-height: 2rem;

    &:hover {
      background-color: #045af2;
    }
  }
}

.box2 {
  background: url("~@/assets/bg/解决方案_项目_还原施工现场@3x.jpg") no-repeat
    center center;
  background-size: cover;
  height: 40rem;
  overflow: hidden;

  .ttl {
    margin-top: 7rem;
    font-size: 2rem;
    font-weight: bold;
    color: #fff;
    line-height: 3rem;
  }

  .sub_ttl {
    margin-top: 1.6rem;
    width: 30rem;
    font-size: 1rem;
    color: #fff;
    line-height: 1.5rem;
  }
}

.box3 {
  overflow: hidden;
  background-color: #fff;
  padding: 7rem 0;

  .title {
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    color: #1a1a1a;
    line-height: 3rem;
  }

  .tabs {
    margin-top: 1.6rem;
    display: flex;
    justify-content: center;

    .item {
      width: 8rem;
      height: 2rem;
      line-height: 2rem;
      border: 1px solid #ddd;
      border-radius: 0.3rem;
      text-align: center;
      font-size: 0.9rem;
      color: #666;
      margin-right: 0.9rem;
      cursor: pointer;

      &:last-child {
        margin-right: 0;
      }

      &:hover,
      &.z-crt {
        background-color: #2574ff;
        border-color: #2574ff;
        color: #fff;
      }
    }
  }

  .main_box {
    .swp_wrap {
      position: relative;
      overflow: hidden;

      .swp {
        height: 25rem;
        margin: 0 auto;

        .group {
          padding-top: 3.3rem;
          overflow: hidden;
          width: $mainWidth + 3rem;
        }
      }
    }
  }

  .case_item {
    float: left;
    margin-right: 2.7rem;
    width: 15rem;
    cursor: pointer;
    transition: transform 0.3s;

    &:hover {
      transform: translate3d(0, -0.15rem, 0);
    }

    .cover {
      width: 100%;
      height: 15rem;
      background-size: cover;
    }

    .info {
      margin-top: 0.8rem;
      padding: 0 0.5rem;
      overflow: hidden;
    }

    .ttl {
      font-size: 0.9rem;
      color: #1a1a1a;
      line-height: 1.35rem;
    }

    .desc {
      margin-top: 0.35rem;
      font-size: 0.7rem;
      color: #858b96;
      line-height: 1.1rem;
      max-height: 2.5rem;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }

    .more {
      margin-top: 0.6rem;
      font-size: 0.7rem;
      font-weight: bold;
      color: #0078f0;
      line-height: 1rem;

      .i {
        display: block;
        margin-left: 0.2rem;
        width: 0.8rem;
        height: 0.8rem;
      }
    }
  }
}

.box4 {
  padding: 6.5rem 0 5rem;
  overflow: hidden;
  background-color: #fff;

  .title {
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    color: #1a1a1a;
    line-height: 3rem;
  }

  .main_box {
    margin-top: 5.5rem;

    &:last-child {
      margin-top: 3rem;
    }
  }

  .col {
    width: 15rem;
    text-align: center;

    .icon {
      display: block;
      width: 5rem;
      height: 5rem;
      margin: auto;
    }

    .ttl {
      margin-top: 0.7rem;
      font-size: 1rem;
      font-weight: bold;
      text-align: center;
      color: #1a1a1a;
      line-height: 1.5rem;
    }

    .desc {
      margin-top: 0.6rem;
      font-size: 0.7rem;
      text-align: center;
      color: #9a9a9a;
      line-height: 1.2rem;
    }
  }
}

.box5 {
  overflow: hidden;
  background-color: #f0f1f6;
  padding: 7rem 0;

  .title {
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    color: #1a1a1a;
    line-height: 3rem;
  }

  .row {
    margin-top: 3rem;
  }

  .col {
    .pic {
      display: block;
      width: 21.25rem;
      height: 14.2rem;
    }

    .ttl {
      margin-top: 1.35rem;
      font-size: 1.2rem;
      text-align: center;
      color: #1a1a1a;
      line-height: 1.8rem;
    }
  }
}

.trial_bar {
  background-color: #f0f1f6;
  padding: 0 0 1.6rem;

  .main_box {
    background: url("~@/assets/bg/申请体验背景.png") no-repeat center center;
    background-size: cover;
    height: 7rem;
  }

  .ttl {
    margin-left: 4rem;
    font-size: 1.8rem;
    font-weight: bold;
    color: #fff;
    line-height: 2.5rem;
    letter-spacing: 0.15rem;
  }

  .btn {
    margin-right: 4rem;
    width: 7rem;
    height: 2.4rem;
    background-color: #fff;
    border-radius: 0.5rem;
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
    color: #0078f0;
    line-height: 2.4rem;
    cursor: pointer;

    &:hover {
      background-color: #dce8ff;
    }
  }
}

.main_box {
  width: $mainWidth;
  margin: auto;
}
</style>
